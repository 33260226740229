<template>
  <div class="w-full">
    <multiselect
      :class="OtherClass"
      v-model="ItemSelected"
      :options="citys"
      @select="setItem"
      :close-on-select="true"
      label="name"
      track-by="_id"
      placeholder="Quelle ville ?"
      ref="mySelect"
    >
      <template slot="noResult">Il n'y a aucun résultat</template>
      <template slot="noOptions">Saisissez une ville!</template>
    </multiselect>
  </div>
</template>

<script>
export default {
  props: {
    selectedItem: {
      type: Object,
      required: false,
    },
    OtherClass: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      citys: [],
      ItemSelected: {},
    };
  },
  mounted() {
    this.onSearchSpeciality();
    this.ItemSelected = this.selectedItem;
  },
  methods: {
    async onSearchSpeciality(search) {
      const data = await this.$store.dispatch("location/GET_CITIES_LIST", {});
      if (data.ok) {
        this.citys = data.data;
      }
    },
    setItem(item) {
      this.ItemSelected = item;
      this.$refs.mySelect.deactivate();
      this.$emit("update:selected-item", this.ItemSelected);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
